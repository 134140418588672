import React, { useState, useRef, useEffect } from 'react';
import './Navbar.css';
import jellyLogo from './img/jelly.png';
import logoutIcon from './img/logout_icon.png'; // Pfad zum Logout-Icon
import joystickIcon from "./img/joystick.png"
import settingsIcon from "./img/settings.png"

const Navbar = ({ onSelect, selected, role, setToken }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const handleLogout = () => {
    // Token aus localStorage entfernen
    localStorage.removeItem('usertoken');
    // Token im Zustand zurücksetzen
    setToken('');
  };

  // Schließt das Menü, wenn man außerhalb klickt
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSelect = (selected) => {
    onSelect(selected);
    setIsMenuOpen(false); // Menü schließen nach Auswahl
  };

  const toggleMenu = () => {
    setIsMenuOpen(prevState => !prevState);  // Der Zustand wird umgekehrt
  };
    
  return (
    <nav className="navbar">
      <div className="logo-container" onClick={toggleMenu}>
        <img className="logo" src={jellyLogo} alt="Logo" />
      </div>
      <div className={`menu ${isMenuOpen ? 'active' : ''}`} ref={menuRef}>
        {role === 'admin' && (
          <>
            <button className={selected === 'ServerManager' ? 'active' : ''} onClick={() => handleSelect('ServerManager')}>Server Manager</button>
            <button className={selected === 'AdminConsole' ? 'active' : ''} onClick={() => handleSelect('AdminConsole')}>Admin Console</button>
            <button className={selected === 'DockerLogs' ? 'active' : ''} onClick={() => handleSelect('DockerLogs')}>Docker Logs</button>
            <button className={selected === 'StatsChart' ? 'active' : ''} onClick={() => handleSelect('StatsChart')}>Stats Chart</button>
            <button className={selected === 'Register' ? 'active' : ''} onClick={() => handleSelect('Register')}>Register</button>
            <button className={selected === 'UserAdmin' ? 'active' : ''} onClick={() => handleSelect('UserAdmin')}>User Admin</button>
            <button className={selected === 'ServerModManager' ? 'active' : ''} onClick={() => handleSelect('ServerModManager')}>Server Mod Manager</button>
            <button className={selected === 'StripePaymentWrapper' ? 'active' : ''} onClick={() => handleSelect('StripePaymentWrapper')}>Payment</button>
            <button className={selected === 'ChangePassword' ? 'active' : ''} onClick={() => handleSelect('ChangePassword')}>User Settings</button>
          </>
        )}
        {role !== 'admin' && (
          <>
            <div class = "navbar_element">
              <img src={joystickIcon} style={{ width: '2.5em', height: '2.5em' }} alt="Joystick Icon" />
              <button className={selected === 'UserServerManager' ? 'active' : ''} onClick={() => handleSelect('UserServerManager')}>Servers</button>
            </div>

            <div class = "navbar_element">
              <img src={settingsIcon} style={{ width: '2.2em', height: '2.2em' }} alt="Joystick Icon" />
              <button className={selected === 'ChangePassword' ? 'active' : ''} onClick={() => handleSelect('ChangePassword')}>Settings</button>
            </div>

            <button className={selected === 'StripePaymentWrapper' ? 'active' : ''} onClick={() => handleSelect('StripePaymentWrapper')}>Payment</button>

            <div class ="navbar_element">
              <button onClick={handleLogout}>
                <img src={logoutIcon} alt="Logout" style={{ width: '2em', height: '2em'}} />Logout
              </button>
            </div>

          </>
        )}
      </div>

    </nav>
  );
};

export default Navbar;
