import React, { useState, useEffect, Suspense, lazy } from 'react';
import axios from 'axios';
import Navbar from './Navbar';
import Login from './Login';
import { loadStripe } from '@stripe/stripe-js';
import './styles.css';

// Lazy-loaded components
const ServerManager = lazy(() => import('./ServerManager'));
const AdminConsole = lazy(() => import('./AdminConsole'));
const ServerStatus = lazy(() => import('./ServerStatus'));
const DockerLogs = lazy(() => import('./DockerLogs'));
const StatsChart = lazy(() => import('./StatsChart'));
const Register = lazy(() => import('./Register'));
const UserServerManager = lazy(() => import('./UserServerManager'));
const StripePaymentWrapper = lazy(() => import('./StripePaymentWrapper'));
const ChangePassword = lazy(() => import('./ChangePassword'));
const UserAdmin = lazy(() => import('./UserAdmin'));
const ServerModManager = lazy(() => import('./ServerModManager'));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const App = () => {
  const [token, setToken] = useState('');
  const [role, setRole] = useState('');
  const [hasPaid, setHasPaid] = useState(false);
  const [selectedModule, setSelectedModule] = useState('UserServerManager');

  useEffect(() => {
    const fetchUserRoleAndPayment = async () => {
      if (token) {
        try {
          const response = await axios.get('https://jelly-hosting.com/user-details', {
            headers: { 'Authorization': `Bearer ${token}` }
          });
          setRole(response.data.role);
          setHasPaid(response.data.hasPaid);
        } catch (error) {
          console.error('Error fetching user details:', error);
        }
      }
    };

    fetchUserRoleAndPayment();
  }, [token]);

  if (!token) {
    return <Login setToken={setToken} setRole={setRole} setHasPaid={setHasPaid} />;
  }

  const renderModule = () => {
    if (role === 'admin') {
      switch (selectedModule) {
        case 'ServerManager':
          return <ServerManager key={selectedModule} token={token} />;
        case 'AdminConsole':
          return <AdminConsole key={selectedModule} token={token} />;
        case 'DockerLogs':
          return <DockerLogs key={selectedModule} token={token} />;
        case 'StatsChart':
          return <StatsChart key={selectedModule} token={token} />;
        case 'ServerStatus':
          return <ServerStatus key={selectedModule} token={token} />;
        case 'Register':
          return <Register key={selectedModule} />;
        case 'UserServerManager':
          return <UserServerManager key={selectedModule} token={token} />;
        case 'UserAdmin': 
          return <UserAdmin key={selectedModule} token={token} />;
        case 'ServerModManager': 
          return <ServerModManager key={selectedModule} token={token} />;
        case 'ChangePassword':
          return <ChangePassword key={selectedModule} token={token} />;
        case 'StripePaymentWrapper':
          return <StripePaymentWrapper key={selectedModule} token={token} />;
        default:
          return <ServerManager key={selectedModule} token={token} />;
      }
    } else {
      switch (selectedModule) {
        case 'UserServerManager':
          return hasPaid ? <UserServerManager key={selectedModule} token={token} /> : <div>Access denied. You must be an admin or have paid to access this module.</div>;
        case 'ChangePassword':
          return <ChangePassword key={selectedModule} token={token} />;
        case 'StripePaymentWrapper':
          return <StripePaymentWrapper key={selectedModule} token={token} />;
        default:
          return <div>Access denied. You must be an admin or have paid to access this module.</div>;
      }
    }
  };

  return (
    <div className="app-container">
      <Navbar onSelect={setSelectedModule} selected={selectedModule} token={token} role={role} setToken={setToken} />
      <div className="content">
        <Suspense fallback={<div>Loading...</div>}>
          {renderModule()}
        </Suspense>
      </div>
    </div>
  );
};

export default App;
