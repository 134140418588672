import React, { useState } from 'react';
import axios from 'axios';
import './Login.css';

const Login = ({ setToken, setRole, setHasPaid }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://jelly-hosting.com/login', { username, password });
      const token = response.data.token;
      setToken(token);
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setRole(decodedToken.role);
      setHasPaid(decodedToken.hasPaid);
    } catch (error) {
      alert('Login failed: ' + (error.response ? error.response.data : error.message));
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
       
        <button class = "round_button" type="submit">Login</button>

        
      </form>
    </div>
  );
};

export default Login;
